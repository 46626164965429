import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Button from 'components/Button'
import { style, Container } from './LoadingScreen.style'

const settings = {
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 1500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '15px',
  // initialSlide: 0,
  // adaptiveHeight: true,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const interval = 400
class LoadingScreen extends Component {
  state = {
    chairIndex1: 0,
    chairIndex2: 1,
  }
  componentDidMount() {
    setTimeout(() => {
      this.interval1 = setInterval(() => {
        const { chairIndex1 } = this.state
        const newI = (chairIndex1 + 1) % images.length
        this.setState({ chairIndex1: newI })
      }, interval)
    }, 0)
    setTimeout(() => {
      this.interval2 = setInterval(() => {
        const { chairIndex2 } = this.state
        const newI = (chairIndex2 + 1) % images.length
        this.setState({ chairIndex2: newI })
      }, interval)
    }, interval / 2)
  }
  componentDidUpdate(prevProps) {
    const { hideLoader, isLoaded } = this.props
    const { isLoaded: wasLoaded } = prevProps

    if (!wasLoaded && isLoaded) {
      setTimeout(hideLoader, 2000)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval1)
    clearInterval(this.interval2)
  }
  render() {
    const { isLoaded } = this.props
    const { chairIndex1, chairIndex2 } = this.state
    // const { src, title, content } = images[chairIndex]
    return (
      <Container className={isLoaded ? 'loaded' : 'loading'}>
        <div className="logoWrapper">
          <div className="logo" />
          <div className="buttonWrapper">
            <div className="message shown">
              Loading Chair Customizer. Please allow 5-10 seconds to load. It will be worth the wait.
              <br /> We promise.
            </div>
          </div>
        </div>
        <div className="morphingWrapper">
          <div className="chairWrapper chair1">
            <div className="chairImage">
              <img src={images[chairIndex1].src} />
            </div>
            {/* <h3 className="title">{images[chairIndex1].title}</h3> */}
            {/* <p className="content">{images[chairIndex1].content}</p> */}
          </div>
          {/* <div className="chairWrapper chair2">
            <div className="chairImage">
              <img src={images[chairIndex2].src} />
            </div>
          </div> */}
        </div>
        {/* <div className="carouselWrapper">
          <Slider {...settings}>
            {[...new Set(images)].map(({ src, title, content }) => {
              return (
                <div>
                  <div className="chairImage">
                    <img src={src} />
                  </div>
                  <h3 className="title">{title}</h3>
                  <p className="content">{content}</p>
                </div>
              )
            })}
          </Slider>
        </div> */}
      </Container>
    )
  }
}

LoadingScreen.propTypes = {}

const images = [...Array(6)].map((_, i) => ({
  src: require(`img/starting/chaise${i + 1}.jpg`),
}))

// const images = [
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-9_s_indigo.png',
//     title: 'I9',
//     content: 'with Indigo Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-1_v_charcoal.png',
//     title: 'N1',
//     content: 'with Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-2_v_hazelnut.png',
//     title: 'N2',
//     content: 'with Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-5_s_french_vanilla.png',
//     title: 'N5',
//     content: 'with French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-9_suede_midnight.png',
//     title: 'N9',
//     content: 'with Midnight Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-1_v_fire_engine_red.png',
//     title: 'C1\n',
//     content: 'with Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-2_v_mist_blue.png',
//     title: 'C2',
//     content: 'with Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-3_suede_grape.png',
//     title: 'C3',
//     content: 'with Grape Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-5_s_caramel.png',
//     title: 'C5',
//     content: 'with Caramel Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-1_s_ash.png',
//     title: 'I1 ',
//     content: 'with Ash Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-1_suede_rust.png',
//     title: 'I1',
//     content: 'with Rust Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-6_s_shiitake.png',
//     title: 'I6',
//     content: 'with Shiitake Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-9_s_indigo.png',
//     title: 'I9',
//     content: 'with Indigo Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-1_v_charcoal.png',
//     title: 'N1',
//     content: 'with Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-2_v_hazelnut.png',
//     title: 'N2',
//     content: 'with Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-5_s_french_vanilla.png',
//     title: 'N5',
//     content: 'with French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-9_suede_midnight.png',
//     title: 'N9',
//     content: 'with Midnight Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-1_v_fire_engine_red.png',
//     title: 'C1\n',
//     content: 'with Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-2_v_mist_blue.png',
//     title: 'C2',
//     content: 'with Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-3_suede_grape.png',
//     title: 'C3',
//     content: 'with Grape Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/C-5_s_caramel.png',
//     title: 'C5',
//     content: 'with Caramel Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-1_s_ash.png',
//     title: 'I1 ',
//     content: 'with Ash Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-1_suede_rust.png',
//     title: 'I1',
//     content: 'with Rust Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-6_s_shiitake.png',
//     title: 'I6',
//     content: 'with Shiitake Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/I-9_s_indigo.png',
//     title: 'I9',
//     content: 'with Indigo Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-1_v_charcoal.png',
//     title: 'N1',
//     content: 'with Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-2_v_hazelnut.png',
//     title: 'N2',
//     content: 'with Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-5_s_french_vanilla.png',
//     title: 'N5',
//     content: 'with French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/N-9_suede_midnight.png',
//     title: 'N9',
//     content: 'with Midnight Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-1_v_mist_blue.png',
//     title: 'D1 with Walnut Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-2_suede_bordeaux.png',
//     title: 'D2 with Walnut Burl',
//     content: 'in Bordeaux Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-5_s_desert_clay.png',
//     title: 'D5 with Walnut Burl',
//     content: 'in Desert Clay Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-9_suede_graphite.png',
//     title: 'D9 with Walnut Burl',
//     content: 'in Graphite Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-9_v_fire_engine_red.png',
//     title: 'D9 with Walnut Burl',
//     content: 'in Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-1_s_black_onyx.png',
//     title: 'B1',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-1_s_evening_blue-1.png',
//     title: 'B1',
//     content: 'in Evening Blue Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-2_v_mist_blue.png',
//     title: 'B2',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-3_s_french_vanilla.png',
//     title: 'B3',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-5_s_cabernet.png',
//     title: 'B5',
//     content: 'in Cabernet Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-6_v_charcoal.png',
//     title: 'B6',
//     content: 'in Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-1_v_hazelnut.png',
//     title: 'D1 with Walnut Burl',
//     content: 'in Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-1_v_mist_blue.png',
//     title: 'D1 with Walnut Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-2_suede_bordeaux.png',
//     title: 'D2 with Walnut Burl',
//     content: 'in Bordeaux Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-5_s_desert_clay.png',
//     title: 'D5 with Walnut Burl',
//     content: 'in Desert Clay Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-9_suede_graphite.png',
//     title: 'D9 with Walnut Burl',
//     content: 'in Graphite Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-9_v_fire_engine_red.png',
//     title: 'D9 with Walnut Burl',
//     content: 'in Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-1_s_black_onyx.png',
//     title: 'B1',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-1_s_evening_blue-1.png',
//     title: 'B1',
//     content: 'in Evening Blue Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-2_v_mist_blue.png',
//     title: 'B2',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-3_s_french_vanilla.png',
//     title: 'B3',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-5_s_cabernet.png',
//     title: 'B5',
//     content: 'in Cabernet Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/B-6_v_charcoal.png',
//     title: 'B6',
//     content: 'in Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-1_v_hazelnut.png',
//     title: 'D1 with Walnut Burl',
//     content: 'in Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-1_v_mist_blue.png',
//     title: 'D1 with Walnut Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-2_suede_bordeaux.png',
//     title: 'D2 with Walnut Burl',
//     content: 'in Bordeaux Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-5_s_desert_clay.png',
//     title: 'D5 with Walnut Burl',
//     content: 'in Desert Clay Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-9_suede_graphite.png',
//     title: 'D9 with Walnut Burl',
//     content: 'in Graphite Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/D-9_v_fire_engine_red.png',
//     title: 'D9 with Walnut Burl',
//     content: 'in Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-1_v_fire_engine_red_Piano_Black.png',
//     title: 'J1 with Piano Black',
//     content: 'in Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-2_v_mist_blue_Walnut_Burl.png',
//     title: 'J2 with Walnut Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-3_s_black_onyx_Rosewood.png',
//     title: 'J3 with Rosewood',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-5_suede_graphite_White_Oak.png',
//     title: 'J5 with White Oak\n',
//     content: 'in Graphite Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-6_s_beet_root_Walnut_Burl.png',
//     title: 'J6 with Walnut Burl\n',
//     content: 'in Beet Root Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-1_s_black_onyx_Macassar.png',
//     title: 'F1 with Macassar Ebony',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-1_s_pompeian_red_PianoBlack.png',
//     title: 'F1 with Piano Black',
//     content: 'in Pompeian Red Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-2_s_ash_Maple_Burl.png',
//     title: 'F2 with Maple Burl',
//     content: 'in Ash Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-5_s_peat_White_Oak.png',
//     title: 'F5 with White Oak',
//     content: 'in Peat Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-5_suede_berry_Macassar.png',
//     title: 'F5 with Macassar Ebony',
//     content: 'in Berry Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-9_s_french_vanilla_Macassar.png',
//     title: 'J9 with Macassar Ebony',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-1_v_fire_engine_red_Piano_Black.png',
//     title: 'J1 with Piano Black',
//     content: 'in Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-2_v_mist_blue_Walnut_Burl.png',
//     title: 'J2 with Walnut Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-3_s_black_onyx_Rosewood.png',
//     title: 'J3 with Rosewood',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-5_suede_graphite_White_Oak.png',
//     title: 'J5 with White Oak\n',
//     content: 'in Graphite Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-6_s_beet_root_Walnut_Burl.png',
//     title: 'J6 with Walnut Burl\n',
//     content: 'in Beet Root Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-1_s_black_onyx_Macassar.png',
//     title: 'F1 with Macassar Ebony',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-1_s_pompeian_red_PianoBlack.png',
//     title: 'F1 with Piano Black',
//     content: 'in Pompeian Red Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-2_s_ash_Maple_Burl.png',
//     title: 'F2 with Maple Burl',
//     content: 'in Ash Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-5_s_peat_White_Oak.png',
//     title: 'F5 with White Oak',
//     content: 'in Peat Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/F-5_suede_berry_Macassar.png',
//     title: 'F5 with Macassar Ebony',
//     content: 'in Berry Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-9_s_french_vanilla_Macassar.png',
//     title: 'J9 with Macassar Ebony',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-1_v_fire_engine_red_Piano_Black.png',
//     title: 'J1 with Piano Black',
//     content: 'in Fire Engine Red Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-2_v_mist_blue_Walnut_Burl.png',
//     title: 'J2 with Walnut Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-3_s_black_onyx_Rosewood.png',
//     title: 'J3 with Rosewood',
//     content: 'in Black Onyx Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-5_suede_graphite_White_Oak.png',
//     title: 'J5 with White Oak\n',
//     content: 'in Graphite Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/J-6_s_beet_root_Walnut_Burl.png',
//     title: 'J6 with Walnut Burl\n',
//     content: 'in Beet Root Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_s_french_vanilla_Walnut_Burl.png',
//     title: 'S7 with Walnut Burl',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_s_pompeian_red_Piano_Black.png',
//     title: 'S7 with Piano Black',
//     content: 'in Pompeian Red Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_suede_bordeaux_Piano_Black.png',
//     title: 'S7 with Piano Black',
//     content: 'in Bordeaux Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-12_suede_navy_Rosewood.png',
//     title: 'S12 with Rosewood\n',
//     content: 'in Navy Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-12_v_Hazelnut_White_Oak.png',
//     title: 'S12 with White Oak',
//     content: 'in Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-3_s_shiitake_Walnut_Burl-1.png',
//     title: 'S3 with Walnut Burl',
//     content: 'in Shiitake Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-3_suede_berry_Piano_Black.png',
//     title: 'S3 with Piano Black',
//     content: 'in Berry Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-3_suede_grape_Rosewood.png',
//     title: 'S3 with Rosewood',
//     content: 'in Grape Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-5_v_charcoal_Macassar.png',
//     title: 'S5 with Macassar Ebloy',
//     content: 'in Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-5_v_Mist_Blue_Maple_Burl.png',
//     title: 'S5 with Maple Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_s_french_vanilla_Walnut_Burl.png',
//     title: 'S7 with Walnut Burl',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_s_pompeian_red_Piano_Black.png',
//     title: 'S7 with Piano Black',
//     content: 'in Pompeian Red Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_suede_bordeaux_Piano_Black.png',
//     title: 'S7 with Piano Black',
//     content: 'in Bordeaux Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-12_suede_navy_Rosewood.png',
//     title: 'S12 with Rosewood\n',
//     content: 'in Navy Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-12_v_Hazelnut_White_Oak.png',
//     title: 'S12 with White Oak',
//     content: 'in Hazelnut Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-3_s_shiitake_Walnut_Burl-1.png',
//     title: 'S3 with Walnut Burl',
//     content: 'in Shiitake Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-3_suede_berry_Piano_Black.png',
//     title: 'S3 with Piano Black',
//     content: 'in Berry Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-3_suede_grape_Rosewood.png',
//     title: 'S3 with Rosewood',
//     content: 'in Grape Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-5_v_charcoal_Macassar.png',
//     title: 'S5 with Macassar Ebloy',
//     content: 'in Charcoal Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-5_v_Mist_Blue_Maple_Burl.png',
//     title: 'S5 with Maple Burl',
//     content: 'in Mist Blue Valentino Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_s_french_vanilla_Walnut_Burl.png',
//     title: 'S7 with Walnut Burl',
//     content: 'in French Vanilla Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_s_pompeian_red_Piano_Black.png',
//     title: 'S7 with Piano Black',
//     content: 'in Pompeian Red Silk Leather',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-7_suede_bordeaux_Piano_Black.png',
//     title: 'S7 with Piano Black',
//     content: 'in Bordeaux Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-12_suede_navy_Rosewood.png',
//     title: 'S12 with Rosewood\n',
//     content: 'in Navy Cinesuede',
//   },
//   {
//     src: 'https://elitehts.com/wp-content/uploads/2018/11/S-12_v_Hazelnut_White_Oak.png',
//     title: 'S12 with White Oak',
//     content: 'in Hazelnut Valentino Leather',
//   },
// ]

export default LoadingScreen
