export default ({
  name,
  email,
  phone,
  location,
  notes,
  companyName,
  companyWebsite,
  companyEmail,
  companyPhone,
  companyTypes,
  additionalRequirements,
  chairProps,
  url,
}) => {
  return `
    <table
      class="m_4726344385676614233body"
      style="height:100%!important;width:100%!important;border-spacing:0;border-collapse:collapse"
    >
      <tbody>
        <tr>
          <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
            <table
              class="m_4726344385676614233row m_4726344385676614233content"
              style="width:100%;border-spacing:0;border-collapse:collapse"
            >
              <tbody>
                <tr>
                  <td
                    class="m_4726344385676614233content__cell"
                    style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;padding-bottom:40px'
                  >
                    <center>
                      <table
                        class="m_4726344385676614233container"
                        style="width:560px;text-align:left;border-spacing:0;border-collapse:collapse;margin:0 auto"
                      >
                        <tbody>
                          <tr>
                            <td style='font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'>
                              <p style="color:#777!important;line-height:150%;font-size:16px;margin:0">
                                <br />
                                The custom chaise quotation request details:
                                <br />
                                <br />
                                <style>.datagrid table { border-collapse: collapse; text-align: left; width: 100%; } .datagrid {font: normal 12px/150% Arial, Helvetica, sans-serif; background: #fff; overflow: hidden; }.datagrid table td, .datagrid table th { padding: 6px 10px; }.datagrid table tbody td { color: #7D7D7D!important; font-size: 14px;border-bottom: 1px solid #E1EEF4;font-weight: normal; }.datagrid table tbody td:first-child { border-left: none; }.datagrid table tbody tr:last-child td { border-bottom: none; }</style>
                                <div class="datagrid">
                                  <table>
                                    <tbody>
                                      <tr><td><b>Name</b></td><td>${name || '---'}</td></tr>
                                      <tr><td><b>Email</b></td><td>${email || '---'}</td></tr>
                                      <tr><td><b>Phone</b></td><td>${phone || '---'}</td></tr>
                                      <tr><td><b>Location</b></td><td>${location || '---'}</td></tr>
                                      <tr><td><b>Additional Notes</b></td><td>${notes || '---'}</td></tr>
                                      <tr><td><b>Company Name</b></td><td>${companyName || '---'}</td></tr>
                                      <tr><td><b>Company Website</b></td><td>${companyWebsite || '---'}</td></tr>
                                      <tr><td><b>Company Email</b></td><td>${companyEmail || '---'}</td></tr>
                                      <tr><td><b>Company Phone</b></td><td>${companyPhone || '---'}</td></tr>
                                      <tr><td><b>Company Type</b></td><td>${(companyTypes && companyTypes.join(', ')) ||
                                        '---'}</td></tr>
                                      <tr><td><b>Additional Requirements</b></td><td>${(additionalRequirements &&
                                        additionalRequirements.join(', ')) ||
                                        '---'}</td></tr>
                                      <tr><td><b></b></td><td></td></tr>
                                      <tr><td><b></b></td><td></td></tr>
                                      <tr><td><b>CHAISE DETAILS: </b></td><td></td></tr>
                                      ${chairProps}
                                    </tbody>
                                  </table>
                                </div>
                                <br />
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  `
}
