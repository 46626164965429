/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactHintFactory from 'react-hint'
import 'react-toastify/dist/ReactToastify.css'
import ReactTooltip from 'react-tooltip'
import ModelFrame from 'components/ModelFrame'
import LoadingScreen from 'components/LoadingScreen'
import Button from 'components/Button'
import Input from 'components/Input'
import Modal from 'components/Modal'
import { chaiseUid } from 'consts/models'
import 'react-hint/css/index.css'
import { sendEmail } from 'services/email-service'
import { chaiseWidth, leather, cupholder, table } from 'helpers'
import { chaiseWidths, leathers, cupholders, tables } from 'mappers'
import { ExportTool, Container } from './Configurator.style'
import { gaTrackNext, gaTrackSubmit, getStepFromQuery, updateStepQuery } from './utils'
const ReactHint = ReactHintFactory(React)

// window.toast = toast

function handler(e) {
  e.stopPropagation()
  e.preventDefault()
}

class Configurator extends Component {
  constructor(props) {
    super(props)
    const { chaiseWidth, leather1, leather2, cupholder, table } = props

    this.state = {
      apiLoaded: false,
      step: getStepFromQuery() || 1,
      loaderIsHidden: false,
      showHint: true,
      isModalOpen: false,
      isRatio50: false,
      showConfirmationPage: false,
      screenshots: {
        inProcess: false,
        width: 500,
        height: 500,
        images: [],
      },
      quantity: 1,
      parts: {
        chaiseWidth: chaiseWidth || chaiseWidths[0],
        leather1: leather1 || leathers[0][0],
        leather2: leather2 || leathers[0][0],
        cupholder: cupholder || cupholders[0],
        withTable: table && table !== 'no',
        // withTable: table === 'no' ? false : true,
        table: !table || table === 'no' ? tables[0] : table,
      },
      form: {
        companyTypes: {},
        additionalRequirements: {},
      },
      goToStep1: false,
    }

    if (window.history.pushState) {
      var newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        this.generateQueryParams(this.state.parts)
      window.history.pushState({ path: newurl }, '', newurl)
    }
  }

  componentDidMount() {
    document.getElementById('configuratorWrapper').addEventListener('click', handler, true)

    setTimeout(() => this.setState({ goToStep1: true, step: 1 }), 3500)
  }

  componentDidUpdate(prevProps, prevState) {
    const { step, parts } = this.state
    if (JSON.stringify(parts) !== JSON.stringify(prevState.parts)) {
      if (window.history.pushState) {
        var newurl =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          this.generateQueryParams(parts)
        window.history.pushState({ path: newurl }, '', newurl)
      }
    }
    if (step !== prevState.step && window.api) {
      this.setState({
        showHint: true,
      })
      if (step === 3 || (step === 4 && window.api2)) {
        leather.changeColor(parts.leather1.overwriteChannels, parts.leather1.hex, window.api2, 'leather_mat')
        cupholder.changeCupholder(parts.cupholder.overwriteChannels, parts.cupholder.hex, window.api2)
        window.api.gotoAnnotation(window.api._getAnnotationByName('step3').index)
      }
      if (step === 1 || step === 2) {
        window.api.gotoAnnotation(window.api._getAnnotationByName('default').index)
      }
    }
  }

  generateQueryParams = (parts, withExport = true) => {
    const { chaiseWidth, leather1, leather2, cupholder, table, withTable, layoutFront, layoutBack } = parts
    const params = {}
    const parameters = new URLSearchParams(window.location.search)

    if (parameters.has('export') && withExport) {
      params['export'] = true
    }

    params['home_theater_seat_width'] = chaiseWidth.name
    params['cupholder'] = cupholder.name
    if (withTable) params['swivel_tray'] = table.name
    else params['swivel_tray'] = 'no'
    params['leather_1_color'] = leather1.colorName
    params['leather_2_color'] = leather2.colorName
    params['leather_1_material'] = leather1.leatherName
    params['leather_2_material'] = leather2.leatherName

    return `?${Object.keys(params)
      .map((param) => `${param}=${params[param]}`)
      .join('&')}`
  }

  onFrameLoaded = () => {
    const { parts } = this.state

    this.cloneChannelsProps()

    this.makeInitialConfiguration(parts)
    setTimeout(
      () =>
        this.setState({ apiLoaded: true }, () =>
          document.getElementById('configuratorWrapper').removeEventListener('click', handler, true)
        ),
      700
    )
  }

  cloneChannelsProps = (api = window.api) => {
    const leather = api && api.materials.filter((m) => m.name === 'cloth_2_mat')[0]
    const cinesuede = api && api.materials.filter((m) => m.name === 'cloth_1_mat')[0]

    api.preloadedMaterials = {}

    if (leather) {
      api.preloadedMaterials.leather = leather
    }

    if (cinesuede) {
      api.preloadedMaterials.cinesuede = cinesuede
    }
  }

  makeInitialConfiguration = (parts) => {
    const {
      chaiseWidth: width,
      leather2: chosenLeather1,
      leather1: chosenLeather2,
      table: chosenTable,
      withTable,
    } = parts
    chaiseWidth.showOneWidth(width.node)
    if (withTable && chosenTable) {
      table.showTable(chosenTable.node)
    } else {
      table.showTable()
    }
    leather.changeColor(chosenLeather1.overwriteChannels, chosenLeather1.hex, window.api, 'cloth_2_mat')
    leather.changeColor(chosenLeather2.overwriteChannels, chosenLeather2.hex, window.api, 'cloth_1_mat')
  }

  updateQuantity = (delta) => {
    const { quantity } = this.state

    this.setState({
      quantity: Math.max(1, quantity + delta),
    })
  }

  changeChaiseWidth = (width) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, chaiseWidth: width }

    this.setState({ parts }, () => {
      chaiseWidth.showOneWidth(width.node)
    })
  }

  changeTable = (newTable) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, table: newTable }

    this.setState({ parts }, () => {
      table.showTable(newTable.node)
      window.api.gotoAnnotation(window.api._getAnnotationByName('table').index)
    })
  }

  toggleWithTable = () => {
    const { parts } = this.state
    const newState = !parts.withTable
    this.setState(
      {
        parts: {
          ...parts,
          withTable: newState,
        },
      },
      () => {
        if (newState) {
          table.showTable((parts.table || tables[0]).node)
          window.api.gotoAnnotation(window.api._getAnnotationByName('table').index)
        } else {
          table.hideAllWidthNodes()
        }
      }
    )
  }

  changeLeather = (chosenLeather, materialName = 'cloth_1_mat') => {
    const paramName = materialName === 'cloth_1_mat' ? 'leather1' : 'leather2'
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, [paramName]: chosenLeather }

    this.setState({ parts }, () => {
      leather.changeColor(chosenLeather.overwriteChannels, chosenLeather.hex, window.api, materialName)
    })
  }

  changeCupholder = (chosenCupholder) => {
    const { parts: prevParts } = this.state
    const parts = { ...prevParts, cupholder: chosenCupholder }

    this.setState({ parts }, () => {
      cupholder.changeCupholder(chosenCupholder.overwriteChannels, chosenCupholder.hex)
      window.api.gotoAnnotation(window.api._getAnnotationByName('cupholders').index)
    })
  }

  getProperties = () => {
    const { parts, quantity } = this.state
    const { chaiseWidth, leather1, leather2, cupholder, table, withTable } = parts
    return {
      // model: arm.buttonName + seat.buttonName,
      width: chaiseWidth.name,
      material_1: leather1.leatherName,
      color_1: leather1.colorName,
      material_2: leather2.leatherName,
      color_2: leather2.colorName,
      cupholder: cupholder.name,
      table: withTable && table.name,
      quantity,
    }
  }

  getPanels = (stepN, side) => {
    const { parts, apiLoaded, step, emailIsSending, goToStep1 } = this.state
    const panels = {
      1: {
        left: (
          <div className={'leftPanel panel' + ((apiLoaded || goToStep1) && step === 1 ? ' ' : ' hidden')} id="leathers">
            <div className="leftPanelContainer panelContainer leatherPanel">
              <div className="group leatherColorsGroup">
                <div className="title">Section 1</div>
                <div className={'colorsWrapper'}>
                  {leathers.map((leather) => (
                    <div className="group leatherColorsGroup">
                      <div className="title">
                        <div
                          className="leatherSampleImg"
                          style={{
                            backgroundImage: `url(${leather[0].sampleImageUrl})`,
                          }}
                        />
                        {leather[0].leatherName}
                        <a className="tooltipTrigger" data-rh={leather[0].tooltip} data-rh-at="bottom">
                          ⓘ
                        </a>
                        <ReactHint events={{ click: true }} autoPosition={false} />
                        <div className="textNote">Custom colors available upon request</div>
                      </div>
                      <div className="buttonsWrapper">
                        <div className="scrollWrapper">
                          {leather.map((color) => (
                            <a
                              data-tip={color.colorName}
                              className={`button ${
                                color.colorName === parts.leather1.colorName &&
                                color.leatherName === parts.leather1.leatherName
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.changeLeather(color, 'cloth_1_mat')}
                            >
                              <span
                                className="image"
                                style={{
                                  backgroundColor: color.hex,
                                }}
                              />
                              {/* for mobile leave this option */}
                              <div className="leatherColorName name onlyMobile">{color.colorName}</div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="group cupholdersGroup hideMobile">
                <div className="title">Choose Your Cupholder Finish</div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {cupholders.map((finish) => (
                      <div
                        className={`cupholderButton button ${finish.name === parts.cupholder.name ? 'active' : ''}`}
                        onClick={() => this.changeCupholder(finish)}
                      >
                        <div className="cupholderFinish name">{finish.name}</div>
                        <div
                          className="cupholderImage"
                          style={{
                            backgroundImage: `url(${finish.buttonImg})`,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
        right: (
          <div className={'rightPanel panel' + ((apiLoaded || goToStep1) && step === 1 ? ' ' : ' hidden')}>
            <div className="rightPanelContainer panelContainer">
              <div className="group leatherColorsGroup">
                <div className="title">Section 2</div>
                <div className={'colorsWrapper'}>
                  {leathers.map((leather) => (
                    <div className="group leatherColorsGroup">
                      <div className="title">
                        <div
                          className="leatherSampleImg"
                          style={{
                            backgroundImage: `url(${leather[0].sampleImageUrl})`,
                          }}
                        />
                        {leather[0].leatherName}
                        <a
                          className="tooltipTrigger"
                          // data-custom
                          data-custom={leather[0].tooltip}
                          data-custom-at="bottom"
                        >
                          ⓘ
                        </a>
                        <ReactHint attribute="data-custom" events={{ click: true }} autoPosition={false} />
                        <div className="textNote">Custom colors available upon request</div>
                      </div>
                      <div className="buttonsWrapper">
                        <div className="scrollWrapper">
                          {leather.map((color) => (
                            <a
                              data-tip={color.colorName}
                              className={`button ${
                                color.colorName === parts.leather2.colorName &&
                                color.leatherName === parts.leather2.leatherName
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => this.changeLeather(color, 'cloth_2_mat')}
                            >
                              <span
                                className="image"
                                style={{
                                  backgroundColor: color.hex,
                                }}
                              />
                              {/* for mobile leave this option */}
                              <div className="leatherColorName name onlyMobile">{color.colorName}</div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="group cupholdersGroup showOnlyMobile">
                <div className="title">Choose Your Cupholder Finish</div>
                <div className="buttonsWrapper">
                  <div className="scrollWrapper">
                    {cupholders.map((finish) => (
                      <div
                        className={`cupholderButton button ${finish.name === parts.cupholder.name ? 'active' : ''}`}
                        onClick={() => this.changeCupholder(finish)}
                      >
                        <div className="cupholderFinish name">{finish.name}</div>
                        <div
                          className="cupholderImage"
                          style={{
                            backgroundImage: `url(${finish.buttonImg})`,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mobileOneRow">
                <div className="group cupholdersGroup">
                  <div className="title">Choose Your Chaise Width</div>
                  <div className="buttonsWrapper">
                    <div className="scrollWrapper">
                      {chaiseWidths.map((width) => (
                        <div
                          className={`cupholderButton button ${width.name === parts.chaiseWidth.name ? 'active' : ''}`}
                          onClick={() => this.changeChaiseWidth(width)}
                        >
                          <div className="cupholderFinish name">{width.name}</div>
                          <div
                            className="cupholderImage"
                            style={{
                              backgroundImage: `url(${width.buttonImage})`,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="group cupholdersGroup pipingGroup">
                  <label className="title">
                    <input
                      type="checkbox"
                      id="cbx2"
                      style={{ display: 'none' }}
                      checked={parts.withTable}
                      onChange={this.toggleWithTable}
                    />
                    <label for="cbx2" class="check">
                      <svg width="18px" height="18px" viewBox="0 0 18 18">
                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                        <polyline points="1 9 7 14 15 4" />
                      </svg>
                    </label>
                    Swivel Tray
                  </label>
                  <div className="buttonsWrapper">
                    <div className={'scrollWrapper' + (!!parts.withTable ? '' : ' disabled')}>
                      {tables.map((table) => (
                        <div
                          className={`cupholderButton button ${
                            parts.table && table.name === parts.table.name ? 'active' : ''
                          }`}
                          onClick={() => this.changeTable(table)}
                        >
                          <div className="cupholderFinish name">{table.name}</div>
                          <div
                            className="cupholderImage"
                            style={{
                              backgroundImage: `url(${table.buttonImage})`,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      2: {
        left: (
          <div className={'leftPanel panel maxWidth' + ((apiLoaded || goToStep1) && step === 2 ? ' ' : ' hidden')}>
            <div className="leftPanelContainer panelContainer reportContainer">
              <div className="report">
                <div className="heading">YOU HAVE CHOSEN</div>
                {Object.keys(this.getProperties()).map(
                  (name) =>
                    this.getProperties()[name] && (
                      <div className="prop">
                        <div className="propName">
                          {name
                            .split('_')
                            .join(' ')
                            .toUpperCase()}
                          :
                        </div>
                        <div className="propValue">{this.getProperties()[name]}</div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        ),
        right: (
          <div
            className={'rightPanel panel submitPanel' + ((apiLoaded || goToStep1) && step === 2 ? ' ' : ' hidden')}
            style={{ backgroundColor: 'rgba(239,239,239,1)' }}
          >
            <div className="rightPanelContainer panelContainer">
              <label className="submitForm">
                <div className="headerTitle">Submit for Quote</div>
                <div className="question">
                  You have selected a custom configuration which is hand made in Canada and comes with an industry
                  leading 20 Year Warranty. <br />
                  <br /> Please allow 1 business day for price quotation. For immediate pricing requests, please call
                  our concierge/sales team at 604-575-8310 (8am to 4pm PST). International residents may also call 0141
                  445 4195 (8:30am to 4:30pm GMT/UTC) <br />
                  <br />
                  Please note retail pricing starts at $3995 MSRP per unit. Wholesale pricing available to registered
                  home professionals including Theater Installers, Interior Designers, Home Builders and Design Firms.
                </div>
                <form onSubmit={this.onSubmit}>
                  <input type="text" autofocus="autofocus" style={{ display: 'none' }} />
                  <Input
                    label="Your Name"
                    contextThis={this}
                    name="name"
                    placeholder="Please fill out this field"
                    required
                  />
                  <Input
                    label="Your Email"
                    contextThis={this}
                    name="email"
                    type="email"
                    placeholder="Please fill out this field"
                    required
                  />
                  <Input
                    label="Your Phone (optional)"
                    contextThis={this}
                    name="phone"
                    type="phone"
                    placeholder="Please fill out this field"
                  />
                  <Input
                    label="Your City & Country"
                    contextThis={this}
                    name="location"
                    placeholder="Please fill out this field"
                    required
                  />
                  <Input
                    label="Additional Notes"
                    textarea
                    contextThis={this}
                    name="notes"
                    placeholder="Please fill out this field"
                  />
                  <label className="formCheckbox">
                    <input
                      type="checkbox"
                      id="registered"
                      style={{ display: 'none' }}
                      checked={this.state.isRegistered}
                      onChange={() =>
                        this.setState({
                          isRegistered: !this.state.isRegistered,
                        })
                      }
                    />
                    <label for="registered" class="check">
                      <svg width="18px" height="18px" viewBox="0 0 18 18">
                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                        <polyline points="1 9 7 14 15 4" />
                      </svg>
                    </label>
                    <div className="label">
                      I am a registered home professional.
                      <br />
                      <span style={{ fontSize: '10px' }}>
                        (ie. Interior Designer, Home Theatre Installer, Home Builder, Design Firm)
                      </span>
                    </div>
                  </label>
                  <input
                    type="submit"
                    hidden
                    ref={(ref) => {
                      this.submit = ref
                    }}
                  />

                  {this.state.isRegistered && (
                    <div className={`additional`}>
                      <div className="horizontalSplit" />
                      <Input
                        label="Company Name"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyName"
                      />
                      <Input
                        label="Company Email"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyEmail"
                      />
                      <Input
                        label="Company Website"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyWebsite"
                      />
                      <Input
                        label="Company Phone"
                        contextThis={this}
                        placeholder="Please fill out this field"
                        required={this.state.isRegistered}
                        name="companyPhone"
                      />
                      <div className="question">Which of the following best describes your company?</div>
                      {[
                        'AV Dealer/Installer',
                        'Design Firm',
                        'Home Builder',
                        'Interior Design',
                        'Architect',
                        'Other',
                      ].map((p, i) => (
                        <label className="formCheckbox">
                          <input
                            type="checkbox"
                            id={'formCheckbox' + i}
                            style={{ display: 'none' }}
                            checked={this.state.form.companyTypes[p]}
                            onChange={() => {
                              const form = this.state.form
                              form.companyTypes = {
                                ...form.companyTypes,
                                [p]: !this.state.form.companyTypes[p],
                              }
                              this.setState({
                                form,
                              })
                            }}
                          />
                          <label for={'formCheckbox' + i} class="check">
                            <svg width="18px" height="18px" viewBox="0 0 18 18">
                              <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                              <polyline points="1 9 7 14 15 4" />
                            </svg>
                          </label>
                          <div className="label">{p}</div>
                        </label>
                      ))}

                      <div className="question">In addition to a price quote, do you require any of the following?</div>
                      {['Material Samples', '3D Layout Drawings'].map((p, i) => (
                        <label className="formCheckbox">
                          <input
                            type="checkbox"
                            id={'formCheckbox0' + i}
                            style={{ display: 'none' }}
                            checked={this.state.form.additionalRequirements[p]}
                            onChange={() => {
                              const form = this.state.form
                              form.additionalRequirements = {
                                ...form.additionalRequirements,
                                [p]: !this.state.form.additionalRequirements[p],
                              }
                              this.setState({
                                form,
                              })
                            }}
                          />
                          <label for={'formCheckbox0' + i} class="check">
                            <svg width="18px" height="18px" viewBox="0 0 18 18">
                              <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                              <polyline points="1 9 7 14 15 4" />
                            </svg>
                          </label>
                          <div className="label">{p}</div>
                        </label>
                      ))}
                    </div>
                  )}
                  <Button
                    className="button submitButton onlyDesktop"
                    wide
                    isLoading={emailIsSending}
                    onClick={(e) => {
                      e.preventDefault()

                      if (this.submit) {
                        this.submit.click()
                        gaTrackSubmit()
                      }
                    }}
                  >
                    <a>Submit for Price Quote</a>
                  </Button>
                </form>
              </label>
            </div>
          </div>
        ),
      },
    }
    return panels[stepN][side]
  }

  getStepHint = (step) => {
    const hints = {
      1: [
        'Welcome to the Elite HTS Chair Customizer',
        'As the manufacturer, we can customize your theater chair anyway you like.',
        'After you have made your selections, please click the NEXT STEP button to view additional options.',
      ],
    }

    return hints[step]
  }

  goToConfirmation() {
    if (window.location.replace) {
      window.location.replace('https://elitehts.com/you-are-awesome-at-this/')
    }
  }

  onSubmit = (e) => {
    const { form } = this.state
    e.preventDefault()

    const props = this.getProperties()
    const chairProps = Object.keys(props)
      .filter((k) => !!props[k])
      .map((p) => ({ name: p, value: props[p] }))
      .map(
        ({ name, value }) =>
          `<tr><td><b>${name
            .split('_')
            .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ')}</b></td><td>${value || '---'}</td></tr>`
      )
      .join('')

    this.setState({
      emailIsSending: true,
    })
    sendEmail(
      {
        ...form,
        companyTypes: Object.keys(form.companyTypes).filter((k) => !!form.companyTypes[k]),
        additionalRequirements: Object.keys(form.additionalRequirements).filter(
          (k) => !!form.additionalRequirements[k]
        ),
        chairProps,
        // url: 'https://elitehts-chaise.tapmod.studio/' + this.generateQueryParams(this.state.parts),
        url: window.location.origin + window.location.pathname + this.generateQueryParams(this.state.parts),
      },
      (msg, status) => {
        this.setState({
          emailIsSending: false,
          status,
          message:
            status === 'success'
              ? 'Thank You! Your quotation request has been successfully submitted. Our current response time is less than 1 business day. For express service, please speak to our project managers at 1-604-575-8310 (8am to 4pm PST)'
              : 'Something went wrong.',
        })
        if (status === 'success') {
          this.goToConfirmation()
        } else {
          toast.error('Something went wrong.', {
            position: 'top-center',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          })
        }
      }
    )
  }

  toggleModal = (show = !this.state.isModalOpen) => {
    if (show) {
      this.makeScreenshots()
    }
    this.setState({ isModalOpen: show })
  }

  makeScreenshots = () => {
    const { screenshots } = this.state
    const { width, height } = screenshots

    screenshots.inProcess = true
    screenshots.images = []

    this.setState({ screenshots }, () => {
      window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot1').index)
      setTimeout(
        () =>
          window.api.getScreenShot(width, height, 'image/png', (err, res1) => {
            window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot2').index)
            setTimeout(() => window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot2').index), 1500)
            setTimeout(() => window.api.gotoAnnotation(window.api._getAnnotationByName('screenshot2').index), 2500)

            screenshots.images.push(res1)
            setTimeout(
              () =>
                window.api.getScreenShot(width, height, 'image/png', (err, res2) => {
                  screenshots.images.push(res2)
                  screenshots.inProcess = false
                  this.setState({ screenshots })
                }),
              4000
            )
          }),
        2000
      )

      window.api.getScreenShot(width, height, 'image/png')
    })
  }

  toggleRatios = () => {
    this.setState({
      isRatio50: !this.state.isRatio50,
    })
  }

  render() {
    const {
      emailIsSending,
      apiLoaded,
      step,
      prevStep,
      loaderIsHidden,
      showHint,
      isModalOpen,
      screenshots,
      quantity,
      isRatio50,
      goToStep1,
    } = this.state
    const { forExport } = this.props
    return (
      <React.Fragment>
        <Container
          className={
            (apiLoaded || goToStep1 ? 'isLoaded' : 'isLoading') + ` step${step} ${isRatio50 ? '' : ' extendedRatio'}`
          }
        >
          {/* {apiLoaded && (
            <ModelFrame
              on2ndFrameLoaded={this.on2ndFrameLoaded}
              uid={layoutsUid}
              hidden={step !== 3 && step !== 4}
              layoutFrame
              minimized={step === 3 || step === 4}
            />
          )} */}
          <ModelFrame
            onFrameLoaded={this.onFrameLoaded}
            uid={chaiseUid}
            isTransparent={!!forExport}
            styles={{ opacity: apiLoaded ? 1 : 0 }}
          />
          {!apiLoaded && (
            <div className="loadingOveflow">
              <svg class="spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
              </svg>
              <div className="loadingMessage">
                Loading Custom Options
                {/* <span>.</span>
                <span>.</span>
                <span>.</span> */}
              </div>
            </div>
          )}
          {!loaderIsHidden && (
            <LoadingScreen
              // isLoaded={false}
              isLoaded={apiLoaded || goToStep1}
              hideLoader={() => this.setState({ loaderIsHidden: true })}
            />
          )}

          <div
            className={
              'hintWindow' + (showHint && !!this.getStepHint(step) ? '' : ' closed') + (step === 3 ? ' blue' : ' grey')
            }
            onClick={() =>
              this.setState({
                showHint: false,
              })
            }
          >
            {/* <div className="close">x</div> */}
            {(this.getStepHint(step) || (prevStep && this.getStepHint(prevStep)) || []).map((text) => (
              <span>
                <br />
                {text}
                <br />
              </span>
            ))}
          </div>
          <div className={'contentWrapper' + (step !== 1 ? ' disabled' : '')}>
            {this.getPanels(1, 'left')}
            <div className="centalPanel" />
            {this.getPanels(1, 'right')}
          </div>
          {/* <div className={'contentWrapper' + (step !== 2 ? ' disabled' : '')}>
            {this.getPanels(2, 'left')}
            <div className="centalPanel" />
            {this.getPanels(2, 'right')}
          </div>
          <div className={'contentWrapper block' + (step !== 3 ? ' disabled' : '')}>
            {this.getPanels(3, 'left')}
            <div className="centalPanel" />
            {this.getPanels(3, 'right')}
          </div> */}
          <div className={'contentWrapper' + (step !== 2 ? ' disabled' : '')}>
            <div className="onlyDesktop">{this.getPanels(2, 'left')}</div>
            <div className="centalPanel" />
            {this.getPanels(2, 'right')}
          </div>
          <div className="onlyMobile mobileReport">{this.getPanels(2, 'left')}</div>
          <div className="progressTabs">
            <div
              className={'tab' + (step === 1 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 1 })}
            >
              <span className="notTablet">STEP 1.</span> Customize Your Seat
            </div>
            {/* <div className="split" />
            <div
              className={'tab' + (step === 2 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 2 })}
            >
              <span className="notTablet">STEP 2.</span> Options
            </div>
            <div className="split" />
            <div
              className={'tab' + (step === 3 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 3 })}
            >
              <span className="notTablet">STEP 3.</span> Layouts
            </div> */}
            <div className="split" />
            <div
              className={'tab' + (step === 2 ? ' active' : '')}
              onClick={() => this.setState({ prevStep: step, step: 2 })}
            >
              <span className="notTablet">STEP 2.</span> Submit for Quotation
            </div>
          </div>
          <div className="buttonsBlock">
            <Button
              className="button back"
              onClick={step > 1 ? this.handlePrev : null}
              // style={step > 1 ? {} : { pointerEvents: 'none', opacity: 0 }}
            >
              <i class="arrow left" />
              <a>BACK</a>
            </Button>
            {forExport && (
              <Button className="button" onClick={() => this.toggleModal(true)}>
                <a>EXPORT</a>
              </Button>
            )}
            <Button className="button resize onlyMobile" onClick={() => this.toggleRatios()}>
              {isRatio50 && <a>⤓</a>}
              {!isRatio50 && <a>⤒</a>}
            </Button>
            <Button
              className="button next"
              onClick={step < 2 ? this.handleNext : null}
              // style={step < 4 ? {} : { visibility: 'hidden', opacity: 0 }}
            >
              <a>NEXT STEP</a>
              <i class="arrow right" />
            </Button>
          </div>
          {step === 2 && (
            <Button
              className="button submitButton onlyMobile"
              wide
              isLoading={emailIsSending}
              onClick={(e) => {
                e.preventDefault()
                if (this.submit) {
                  gaTrackSubmit()
                  this.submit.click()
                }
              }}
            >
              <a>Submit for Price Quote</a>
            </Button>
          )}
          <div className="quantityWrapper">
            <div className="quantity">
              <div className="title">Quantity</div>
              <div className="count">
                <div className="remove button" onClick={() => this.updateQuantity(-1)}>
                  -
                </div>
                <div className="countValue">{quantity}</div>
                <div className="add button" onClick={() => this.updateQuantity(+1)}>
                  +
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip place="top" type="light" effect="solid" globalEventOff="click" />
        </Container>
        <Modal title="Export Tool" isVisible={isModalOpen} closeModal={() => this.toggleModal(false)}>
          <ExportTool>
            <div className="wrapper">
              <div className="texts">
                <div className="label">Chair ID url:</div>
                <div className="text">
                  <div
                    className="prop"
                    style={{
                      maxWidth: '350px',
                      overflow: 'auto',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <div className="propValue">
                      {window.location.protocol +
                        '//' +
                        window.location.host +
                        window.location.pathname +
                        this.generateQueryParams(this.state.parts, false)}
                    </div>
                  </div>
                </div>
                <div className="text">
                  {[
                    'model',
                    'width',
                    'material_1',
                    'color_1',
                    'material_2',
                    'color_2',
                    'cupholder',
                    'table',
                    'quantity',
                  ].map(
                    (name) =>
                      this.getProperties()[name] && (
                        <div className="prop">
                          <div className="propName">
                            {name
                              .split('_')
                              .join(' ')
                              .toUpperCase()}
                            :
                          </div>
                          <div className="propValue">{this.getProperties()[name]}</div>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className="images">
                <div className="inputs">
                  <Input label="Screenshots Width" contextThis={this} name="width" parentObj="screenshots" />
                  <Input label="Screenshots Height" contextThis={this} name="height" parentObj="screenshots" />
                  <Button className="button" isLoading={screenshots.inProcess} onClick={this.makeScreenshots}>
                    <a>Get screenshots</a>
                  </Button>
                </div>
                <div className="screenshots">
                  {screenshots.images.map((img) => (
                    <img src={img} alt="" />
                  ))}
                </div>
              </div>
            </div>
          </ExportTool>
        </Modal>
        {/* {showConfirmationPage && (
          <ConfirmationPage
            goBack={() => this.setState({ showConfirmationPage: false })}
          />
        )} */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </React.Fragment>
    )
  }

  handlePrev = () => {
    const { step } = this.state
    const newStep = step - 1
    updateStepQuery(newStep)
    this.setState({ prevStep: step, step: newStep })
  }

  handleNext = () => {
    const { step } = this.state
    const newStep = step + 1
    gaTrackNext(newStep)
    updateStepQuery(newStep)
    this.setState({ prevStep: step, step: newStep })
  }
}

Configurator.propTypes = {}

export default Configurator
