const hideAllWidthNodes = () => {
  const nodesToHide = ['chaise_wide', 'chaise_narrow']
  const promisses = []
  nodesToHide.forEach((node) => {
    promisses.push(window.api.node_hide(node))
  })
  return Promise.all(promisses)
}

const showOneWidth = (nodeName) => {
  return hideAllWidthNodes().then(() => {
    return window.api.node_show(nodeName)
  })
}

export { showOneWidth }
