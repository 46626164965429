import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SketchfabWrapper from 'sketchfab-api-wrapper'

import { style, Container } from './ModelFrame.style'

import opts from './iframeOptions.json'

class ModelFrame extends Component {
  componentDidMount() {
    this.angle = 0
    this.rotationInterval = 100 //ms
    this.cameraEaseDuration = 2 //s
    this.materialNames = []
    this.materialIndex = 0

    const { uid, onFrameLoaded, on2ndFrameLoaded, layoutFrame, isTransparent, style } = this.props
    const options = layoutFrame
      ? { ...opts, transparent: 1 }
      : {
          ...opts,
          transparent: isTransparent ? 1 : 0,
          // orbit_constraint_pan: 1,
          // orbit_constraint_zoom_in: 150,
          // orbit_constraint_zoom_out: 250,
          // orbit_constraint_pitch_up: 89 / 180,
          // orbit_constraint_pitch_down: -5 / 180,
        }
    const wrapper = new SketchfabWrapper({
      iframeQuery: '#apiFrame' + uid, // e.g. #api-frame
      uid, // sketchfab model id
      options, // options from here: https://sketchfab.com/developers/viewer/initialization
      useSingleton: false,
    })

    wrapper.init().then(() => {
      this.api = { ...wrapper, ...wrapper.api } // Hack to use native sketchfab api inline with wrapper methords
      if (!!onFrameLoaded) {
        window.api = this.api // for easy dev testing from browser console
        onFrameLoaded()
        if (isTransparent) {
          const newMat1 = window.api._getMaterialByName('test_walls_mat')
          newMat1.channels.Opacity.factor = 0
          newMat1.channels.Opacity.enable = true
          window.api.setMaterial(newMat1)

          const newMat2 = window.api._getMaterialByName('fakemat4')
          newMat2.channels.Opacity.factor = 0
          newMat2.channels.Opacity.enable = true
          window.api.setMaterial(newMat2)
        }
      }
      if (!!on2ndFrameLoaded) {
        window.api2 = this.api // for easy dev testing from browser console
        on2ndFrameLoaded()
      }
    })
    // onClientInit(api) // Your custom function to start afer api is ready
  }

  render() {
    const { uid, minimized, hidden, layoutFrame, styles } = this.props

    return (
      <Container
        className={`${minimized ? 'minimized' : ''}${hidden ? ' hidden' : ''}${!hidden && layoutFrame ? ' shown' : ''}${
          layoutFrame ? ' layoutFrame' : ' mainFrame'
        }`}
        style={styles}
      >
        <iframe
          // style={{ opacity: sketchfabLoaded ? 1 : 0 }}
          title={uid}
          id={'apiFrame' + uid}
          // height={iframeHeight}
          // width={iframeWidth}
        />
      </Container>
    )
  }
}

ModelFrame.propTypes = {}

export default ModelFrame
